const physicsSyllabus = [
    {
        topic_no: 1,
        topic_name: 'Measurement and Uncertainties',
        sub_topics: [
            "Measurement in Physics",
            "Uncertainties and Errors",
            "Vectors and Scalars",
        ]
    },
    {
        topic_no: 2,
        topic_name: 'Mechanics',
        sub_topics: [
            "Motion",
            "Forces",
            "Work, energy, and power",
            "Momentum and impulse"
        ]
    },
    {
        topic_no: 3,
        topic_name: 'Thermal physics',
        sub_topics: [
            "Thermal concepts",
            "Modelling a gas",
        ]
    },
    {
        topic_no: 4,
        topic_name: 'Waves',
        sub_topics: [
            "Oscillations",
            "Travelling waves",
            "Wave characteristics",
            "Wave behavior",
            "Standing waves",
        ]
    },
    {
        topic_no: 5,
        topic_name: 'Electricity and magnetism',
        sub_topics: [
            "Electric fields",
            "Heating effect of electric currents",
            "Electric cells",
            "Magnetic effects of electric currents",
        ]
    },
    {
        topic_no: 6,
        topic_name: 'Circular motion and gravitation',
        sub_topics: [
            "Circular motion",
            "Newton's law of gravitation",
        ]
    },
    {
        topic_no: 7,
        topic_name: 'Atomic, nuclear and particle physics',
        sub_topics: [
            "Discrete energy and radioactivity",
            "Nuclear reactions",
            "The structure of matter",
        ]
    },
    {
        topic_no: 8,
        topic_name: 'Energy production',
        sub_topics: [
            "Energy sources",
            "Thermal energy transfer",
        ]
    },
];
const physicsAHLSyllabus = [
    {
        topic_no: 9,
        topic_name: 'Wave phenomena',
        sub_topics: [
            "Simple harmonic motion",
            "Single-slit diffraction",
            "Interference",
            "Resolution",
            "Doppler effect",
        ]
    },
    {
        topic_no: 10,
        topic_name: 'Fields',
        sub_topics: [
            "Describing fields",
            "Fields at work"
        ]
    },
    {
        topic_no: 11,
        topic_name: 'Electromagnetic induction',
        sub_topics: [
            "Electromagnetic induction",
            "Power generation and transmission",
            "Capacitance"
        ]
    },
    {
        topic_no: 12,
        topic_name: 'Quantum and nuclear physics',
        sub_topics: [
            "The interaction of matter with radiation",
            "Nuclear physics",
        ]
    }
];
const physicsOptionsSyllabus = [
    {
        topic_no: 'A',
        topic_name: 'Relativity',
        sub_topics: [
            "The beginnings of relativity",
            "Lorentz transformations",
            "Spacetime diagrams",
        ]
    },
    {
        topic_no: 'B',
        topic_name: 'Engineering physics',
        sub_topics: [
            "Rigid bodies and rotational dynamics",
            "Thermodynamics",
            "Fluids and fluid dynamics (HL only)",
            "Forced vibrations and resonance (HL only)",
        ]
    },
    {
        topic_no: 'C',
        topic_name: 'Imaging',
        sub_topics: [
            "Introduction to imaging",
            "Imaging instrumentation",
            "Fibre optics",
            "Medical imaging (HL only)",
        ]
    },
    {
        topic_no: 'D',
        topic_name: 'Astrophysics',
        sub_topics: [
            "Stellar quantities",
            "Stellar characteristics and stellar evolution",
            "Cosmology",
            "Stellar processes (HL only)",
            "Further cosmology (HL only)",
        ]
    }
];

export { physicsSyllabus, physicsAHLSyllabus, physicsOptionsSyllabus };