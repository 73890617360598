import React, { lazy, Suspense, useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, Redirect, useParams } from 'react-router-dom';
import Navigation from './Navigation';

import { prettifySubject, displayTopic } from './DisplayUtils';

import left from './assets/left.svg';

const Topic = (props) => {
    const params = useParams();

    const Subject = lazy(() => import('./subject_wrappers/' + params.subject));

    return (
        <div className='wrapper'>
            <Navigation />
            <div className='topic-page sticky' id='topic-page'>
                <div className='header' id='header'>
                    <div className='breadcrumb'>
                        <Link className='breadcrumb-link' to={`/${params.subject}`}>
                            <img src={left} />
                            <div className='breadcrumb-subject'>
                                {prettifySubject(params.subject)}
                            </div>
                        </Link>
                        <div className='breadcrumb-topic'>
                            <span className='slash'>
                                /
                            </span>
                            {`${displayTopic(params.subject, params.topic)}`}
                        </div>
                    </div>
                </div>
                <Suspense fallback='Loading question data...'>
                    <Subject subject={params.subject} topic={params.topic} />
                </Suspense>
            </div>
        </div>
    )
}

export default Topic;