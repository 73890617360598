const mathTopicSwitchboard = {
    0: "Prior learning topics ",
    1: "Topic 1 - Algebra",
    2: "Topic 2 - Functions and equations",
    3: "Topic 3 - Circular functions and trigonometry",
    4: "Topic 4 - Vectors",
    5: "Topic 5 - Statistics and probability",
    6: "Topic 6 - Calculus",
}

const chemistryTopicSwitchboard = {
    1: "Topic 1: Stoichiometric relationships",
    2: "Topic 2: Atomic structure",
    3: "Topic 3: Periodicity",
    4: "Topic 4: Chemical bonding and structure",
    5: "Topic 5: Energetics/thermochemistry",
    6: "Topic 6: Chemical kinetics",
    7: "Topic 7: Equilibrium",
    8: "Topic 8: Acids and bases",
    9: "Topic 9: Redox processes",
    10: "Topic 10: Organic chemistry",
    11: "Topic 11: Measurement and data processing",
    12: "Topic 12: Atomic structure",
    13: "Topic 13: The periodic table—the transition metals",
    14: "Topic 14: Chemical bonding and structure",
    15: "Topic 15: Energetics/thermochemistry",
    16: "Topic 16: Chemical kinetics",
    17: "Topic 17: Equilibrium",
    18: "Topic 18: Acids and bases",
    19: "Topic 19: Redox processes",
    20: "Topic 20: Organic chemistry",
    21: "Topic 21: Measurement and analysis",
    'A': "A: Materials",
    'B': "B: Biochemistry",
    'C': "C: Energy",
    'D': "D: Medicinal chemistry",
}

const physicsTopicSwitchboard = {
    1: "Topic 1: Measurements and uncertainties",
    2: "Topic 2: Mechanics",
    3: "Topic 3: Thermal physics",
    4: "Topic 4: Waves",
    5: "Topic 5: Electricity and magnetism",
    6: "Topic 6: Circular motion and gravitation",
    7: "Topic 7: Atomic, nuclear and particle physics",
    8: "Topic 8: Energy production",
    9: "Topic 9: Wave phenomena",
    10: "Topic 10: Fields",
    11: "Topic 11: Electromagnetic induction",
    12: "Topic 12: Quantum and nuclear physics",
    'A': "Option A: Relativity",
    'B': "Option B: Engineering physics",
    'C': "Option C: Imaging",
    'D': "Option D: Astrophysics",
}

const biologyTopicSwitchBoard = {
    1: "Topic 1: Cell biology",
    2: "Topic 2: Molecular biology",
    3: "Topic 3: Genetics",
    4: "Topic 4: Ecology",
    5: "Topic 5: Evolution and biodiversity",
    6: "Topic 6: Human physiology",
    7: "Topic 7: Nucleic acids",
    8: "Topic 8: Metabolism, cell respiration and photosynthesis",
    9: "Topic 9: Plant biology",
    10: "Topic 10: Genetics and evolution",
    11: "Topic 11: Animal physiology",
    'A': "Option A: Neurobiology and behaviour",
    'B': "Option B: Biotechnology and bioinformatics",
    'C': "Option C: Ecology and conservation",
    'D': "Option D: Human physiology",
}

const math_HLTopicSwitchboard = {
    1: "Topic 1 - Core: Algebra",
    2: "Topic 2 - Core: Functions and equations",
    3: "Topic 3 - Core: Circular functions and trigonometry",
    4: "Topic 4 - Core: Vectors",
    5: "Topic 5 - Core: Statistics and probability",
    6: "Topic 6 - Core: Calculus",
    7: "Topic 7 - Option: Statistics and probability",
    8: "Topic 8 - Option: Sets, relations and groups",
    9: "Topic 9 - Option: Calculus",
    10: "Topic 10 - Option: Discrete mathematics",
}

const further_math_HLTopicSwitchboard = {
    1: "Topic 1 - Linear Algebra",
    2: "Topic 2 - Geometry",
    3: "Topic 3 - Statistics and probability",
    4: "Topic 4 - Sets, relations and groups",
    5: "Topic 5 - Calculus",
    6: "Topic 6 - Discrete mathematics",
}

const math_studiesTopicSwitchboard = {
    1: "Topic 1 - Number and algebra",
    2: "Topic 2 - Descriptive statistics",
    3: "Topic 3 - Logic, sets and probability",
    4: "Topic 4 - Statistical applications",
    5: "Topic 5 - Geometry and trigonometry",
    6: "Topic 6 - Mathematical models",
    7: "Topic 7 - Introduction to differential calculus",
}

const geographyTopicSwitchBoard = {
    1: "Populations in transition",
    2: "Disparities in wealth and development",
    3: "Patterns in environmental quality and sustainability",
    4: "Patterns in resource consumption",
    5: "Measuring global interactions",
    6: "Changing space—the shrinking world",
    7: "Economic interactions and flows",
    8: "Environmental change",
    9: "Sociocultural exchanges",
    10: "Political outcomes",
    11: "Global interactions at the local level",
    'A': "Option A: Freshwater—issues and conflicts",
    'B': "Option B: Oceans and their coastal margins",
    'C': "Option C: Extreme environments",
    'D': "Option D: Hazards and disasters—risk assessment and response",
    'E': "Option E: Leisure, sport and tourism",
    'F': "Option F: The geography of food and health",
    'G': "Option G: Urban environments",
}

const environmental_systems_and_societiesTopicSwitchboard = {
    1: "Topic 1: Foundations of environmental systems and societies",
    2: "Topic 2: Ecosystems and ecology",
    3: "Topic 3: Biodiversity and conservation",
    4: "Topic 4: Water and aquatic food production systems and societies",
    5: "Topic 5: Soil systems and terrestrial food production systems and societies",
    6: "Topic 6: Atmospheric systems and societies",
    7: "Topic 7: Climate change and energy production",
    8: "Topic 8: Human systems and resource use",
}
export { mathTopicSwitchboard, chemistryTopicSwitchboard, physicsTopicSwitchboard, biologyTopicSwitchBoard, math_HLTopicSwitchboard, further_math_HLTopicSwitchboard, math_studiesTopicSwitchboard, geographyTopicSwitchBoard, environmental_systems_and_societiesTopicSwitchboard };