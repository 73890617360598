const math_studiesSyllabus = [
    {
        topic_no: 1,
        topic_name: 'Number and Algebra',
        sub_topics: [
            "1.1",
            "1.2",
            "1.3",
            "1.4",
            "1.5",
            "1.6",
            "1.7",
            "1.8",
            "1.9",
        ]
    },
    {
        topic_no: 2,
        topic_name: 'Descriptive statistics',
        sub_topics: [
            "2.1",
            "2.2",
            "2.3",
            "2.4",
            "2.5",
            "2.6",
        ]
    },
    {
        topic_no: 3,
        topic_name: 'Logic, sets and probability',
        sub_topics: [
            "3.1",
            "3.2",
            "3.3",
            "3.4",
            "3.5",
            "3.6",
            "3.7",
        ]
    },
    {
        topic_no: 4,
        topic_name: 'Statistical applications',
        sub_topics: [
            "4.1",
            "4.2",
            "4.3",
            "4.4",
        ]
    },
    {
        topic_no: 5,
        topic_name: 'Geometry and trigonometry',
        sub_topics: [
            "5.1",
            "5.2",
            "5.3",
            "5.4",
            "5.5",
        ]
    },
    {
        topic_no: 6,
        topic_name: 'Mathematical models',
        sub_topics: [
            "6.1",
            "6.2",
            "6.3",
            "6.4",
            "6.5",
            "6.6",
            "6.7",
        ]
    },
    {
        topic_no: 7,
        topic_name: 'Introduction to differential calculus',
        sub_topics: [
            "7.1",
            "7.2",
            "7.3",
            "7.4",
            "7.5",
            "7.6",
        ]
    }
];

export { math_studiesSyllabus };