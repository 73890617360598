const biologySyllabus = [
    {
        topic_no: 1,
        topic_name: 'Cell biology',
        sub_topics: [
            "Introduction to cells",
            "Ultrastructure of cells",
            "Membrane structure",
            "Membrane transport",
            "The origin of cells",
            "Cell division",
        ]
    },
    {
        topic_no: 2,
        topic_name: 'Molecular Biology',
        sub_topics: [
            "Molecules to metabolism",
            "Water",
            "Carbohydrates and lipids",
            "Proteins",
            "Enzymes",
            "Structure of DNA and RNA",
            "DNA replication, transcription and translation",
            "Cell respiration",
            "Photosynthesis",
        ]
    },
    {
        topic_no: 3,
        topic_name: 'Genetics',
        sub_topics: [
            "Genes",
            "Chromosomes",
            "Meiosis",
            "Inheritance",
            "Genetic modification and biotechnology",
        ]
    },
    {
        topic_no: 4,
        topic_name: 'Ecology',
        sub_topics: [
            "Species, communities and ecosystems",
            "Energy flow",
            "Carbon cycling",
            "Climate change",
        ]
    },
    {
        topic_no: 5,
        topic_name: 'Evolution and biodiversity',
        sub_topics: [
            "Evidence for evolution",
            "Natural selection",
            "Classification of biodiversity",
            "Cladistics",
        ]
    },
    {
        topic_no: 6,
        topic_name: 'Human physiology',
        sub_topics: [
            "Digestion and absorption",
            "The blood system",
            "Defence against infectious disease",
            "Gas exchange",
            "Neurons and synapses",
            "Hormones, homeostasis and reproduction",
        ]
    },
]
const biologyAHLSyllabus = [
    {
        topic_no: 7,
        topic_name: 'Nucleic acids',
        sub_topics: [
            "DNA structure and replication",
            "Transcription and gene expression",
            "Translation",
        ]
    },
    {
        topic_no: 8,
        topic_name: 'Metabolism, cell respiration and photosynthesis',
        sub_topics: [
            "Metabolism",
            "Cell respiration",
            "Photosynthesis",
        ]
    },
    {
        topic_no: 9,
        topic_name: 'Plant biology',
        sub_topics: [
            "Transport in the xylem of plants",
            "Transport in the phloem of plants",
            "Growth in plants",
            "Reproduction in plants",
        ]
    },
    {
        topic_no: 10,
        topic_name: 'Genetics and evolution',
        sub_topics: [
            "Meiosis",
            "Inheritance",
            "Gene pools and speciation",
        ]
    },
    {
        topic_no: 11,
        topic_name: ' Animal physiology',
        sub_topics: [
            "Antibody production and vaccination",
            "Movement",
            "The kidney and osmoregulation",
            "Sexual reproduction",
        ]
    }
]
const biologyOptionsSyllabus = [
    {
        topic_no: 'A',
        topic_name: 'Neurobiology and behaviour',
        sub_topics: [
            "Neural development",
            "The human brain",
            "Perception of stimuli",
            "Innate and learned behaviour",
            "Neuropharmacology",
            "Ethology"
        ]
    },
    {
        topic_no: 'B',
        topic_name: 'Biotechnology and bioinformatics',
        sub_topics: [
            "Microbiology: organisms in industry",
            "Biotechnology in agriculture",
            "Environmental protection",
            "Medicine",
            "Bioinformatics"
        ]
    },
    {
        topic_no: 'C',
        topic_name: 'Ecology and conservation',
        sub_topics: [
            "Species and communities",
            "Communities and ecosystems",
            "Impacts of humans on ecosystems",
            "Conservation of biodiversity",
            "Population ecology",
            "Nitrogen and phosphorus cycles"
        ]
    },
    {
        topic_no: 'D',
        topic_name: 'Human physiology',
        sub_topics: [
            "Human nutrition",
            "Digestion",
            "Cosmology",
            "Functions of the liver",
            "The heart",
            "Hormones and metabolism",
            "Transport of respiratory gases",
        ]
    }
];

export { biologySyllabus, biologyAHLSyllabus, biologyOptionsSyllabus };