import React, { useEffect, useState } from 'react';
import { useAuth } from './contexts/AuthContext';
import { Link, useHistory } from 'react-router-dom';
import { database, analytics, remoteConfig } from './firebase';

import demo from './assets/pestle.png';
import SaveIcon from './assets/save.svg';
import ZapIcon from './assets/zap.svg';
import LayoutIcon from './assets/layout.svg';

const Landing = () => {

    const [isKilled, setIsKilled] = useState(false);

    const { currentUser, loginUsingGoogle } = useAuth();

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const history = useHistory();

    useEffect(() => {

        remoteConfig.fetchAndActivate()
            .then(() => {
                if (remoteConfig.getValue("closed_exam_season")._value == "true") {
                    setIsKilled(true);
                } else {
                    setIsKilled(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    async function handleGoogleLogin(e) {
        e.preventDefault();

        try {
            setError('');
            setLoading(true);
            const returnData = await loginUsingGoogle();

            database.collection('users').doc(returnData.user.uid).get().then((doc) => {
                // not the best method, but checking if they're a first time user (or effectively first time--but not effective to do this check every login)
                if (doc.exists) {
                    // is not a new user
                    history.push('/');
                    analytics.logEvent('login', { method: 'Google' });
                    setLoading(false);
                } else if (!doc.exists) {
                    // is a new user

                    database.collection("users").doc(returnData.user.uid).set({
                        id: returnData.user.uid,
                    })
                        .then(function () {
                            console.log("Document successfully written!");
                            history.push('/');
                        })
                        .catch(function (error) {
                            console.error("Error writing document: ", error);
                            setError('Failed to create new record as first time registrant: ' + error);
                        });

                    analytics.logEvent('sign_up', { method: 'Google' });
                    setLoading(false);
                }
            }).then(function () {

            }).catch(function (err) {
                setError('Failed to communicate with server: ' + err);
            });
        } catch {
            setError('login failed. try again.');
        }

        setLoading(false);
    }

    return (
        <div className='landing-wrapper'>
            <div className='landing'>
                <div className='head'>
                    <div className='head-text'>
                        pestle
                    </div>
                    <div className='head-link'>
                        <a className='login-button' href="#start">
                            start grinding
                        </a>
                    </div>
                </div>
                <div className='blackpink'>
                    <div className='text'>
                        <h1><i>Grind</i> Practice Questions</h1>
                        <div className='image-container'>
                            <img draggable={false} src={demo} />
                        </div>
                    </div>
                </div>
                <h2>features</h2>
                <div className='features'>
                    <div className='item'>
                        <img draggable={false} className='save' src={SaveIcon} />
                        <h4>Save Progress</h4>
                        <p>
                            Mark questions complete or revisit. All information is
                            saved to your account.
                        </p>
                    </div>
                    <div className='item'>
                        <img draggable={false} className='layout' src={LayoutIcon} />
                        <h4>Mobile Optimized</h4>
                        <p>
                            Grind questions on the go. We know #thegrindneverstops
                        </p>
                    </div>
                    <div className='item'>
                        <img draggable={false} className='zap' src={ZapIcon} />
                        <h4>Plethora of Questions</h4>
                        <p>
                            An endless supply of questions to grind, with more to be added.
                        </p>
                    </div>
                </div>
                <h2 id="start">start grinding</h2>
                <div className='cta'>
                    <div className='login'>
                        {(!currentUser) ? (
                            (!isKilled) ? (
                                <>
                                    <button disabled={loading} onClick={handleGoogleLogin} className={`google disabled${loading}`}>
                                        {(loading) ? ('logging in...') : ('login with google')}
                                    </button>
                                    {(error) && (
                                        <div className='login-error'>
                                            {error}
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div className='killed-message'>
                                    <div className='killed-message-inner'>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-alert-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>
                                        Sorry! Pestle is currently closed for the exam season.
                                    </div>
                                </div>
                            )
                        ) : (
                            <>
                                <Link className='redirect-button' to='/'>
                                    go to pestle
                                </Link>
                            </>
                        )}
                    </div>
                </div>
                <div className='footer'>
                    pestle &middot; <a href="https://ibdocuments.com">ibdocuments.com</a><br />
                    contact &middot; <a href="https://www.reddit.com/user/pestle_dev">u/pestle_dev</a>
                </div>
            </div>
        </div>
    )
}

export default Landing;