import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/performance";
import "firebase/remote-config";

const app = firebase.initializeApp({
    apiKey: "AIzaSyCa0_PFiDO7RZ2ALoEw8ujlCiE7cmLHfUw",
    authDomain: "cult-of-andrew.firebaseapp.com",
    projectId: "cult-of-andrew",
    databaseURL: "https://cult-of-andrew.firebaseio.com",
    storageBucket: "cult-of-andrew.appspot.com",
    messagingSenderId: "212687185642",
    appId: "1:212687185642:web:a070ef5278534108425993",
    measurementId: "G-MJK1NHMDWL"
});

app.firestore().enablePersistence()
    .catch((err) => {
        if (err.code == 'failed-precondition') {
            // Multiple tabs open, persistence can only be enabled
            // in one tab at a a time.
            // ...
        } else if (err.code == 'unimplemented') {
            // The current browser does not support all of the
            // features required to enable persistence
            // ...
        }
    });

export const analytics = app.analytics();
export const perf = app.performance();
export const auth = app.auth();
export const database = app.firestore();
export const remoteConfig = app.remoteConfig();
// testing only, will result in usage limit exceed if used in prod
// remoteConfig.settings.minimumFetchIntervalMillis = 360000;
remoteConfig.defaultConfig = {
    "closed_exam_season": "false"
};

export default app;