import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, Redirect, useParams, useHistory } from 'react-router-dom';
import './Question.css';

import { prettifySubject, displayTopic, subject_switchboard_mapping, subject_topic_number_mapping, subject_status_mapping, subject_import_mapping } from './DisplayUtils';

import Navigation from './Navigation';

import left from './assets/left.svg';
import eye from './assets/eye.svg';
import eyeOff from './assets/eye-off.svg';

import { useAuth } from './contexts/AuthContext';
import { database, analytics, perf } from './firebase';

const Question = (props) => {

    const [showMarkscheme, setShowMarkscheme] = useState(false);
    const [question, setQuestion] = useState({});

    const [current_subject, setCurrentSubject] = useState({});

    const [paperOneQuestions, setPaperOneQuestions] = useState([]);
    const [paperTwoQuestions, setPaperTwoQuestions] = useState([]);
    const [paperThreeQuestions, setPaperThreeQuestions] = useState([]);

    const [lastPaper, setLastPaper] = useState([]);

    const [user, setUser] = useState({});
    const [questionState, setQuestionState] = useState(''); // revisit, complete, (null)
    const [loading, setLoading] = useState(false);
    const [questionURL, setQuestionURL] = useState('');
    const [markschemeURL, setMarkschemeURL] = useState('');

    const params = useParams();
    const history = useHistory();
    const { currentUser } = useAuth();

    import("./qb-json/" + subject_import_mapping[params.subject]).then(subject => {
        setCurrentSubject(subject[params.subject]);
    });

    useEffect(() => {

        // todo: error handling for when document doesn't exist
        // database.collection(params.subject).doc(params.question).get().then((doc) => {
        //     if (doc.exists) {
        //         setQuestion({
        //             id: doc.id,
        //             data: doc.data(),
        //         });
        //     }
        // });

        setQuestion(current_subject[params.question]);



        database.collection("users").doc(currentUser.uid).onSnapshot((doc) => {

            if (doc.exists) {
                setUser({
                    data: doc.data()
                })
            }
        });

    }, [params.subject, params.question, current_subject]);

    useEffect(() => {
        if (question) {
            setQuestionURL(`/qb/${params.subject}/${question.url}`);
            console.log(`/qb/${params.subject}/${question.url}`);

            console.log("subject:", params.subject);
            console.log("url", question.url);
        }
        if (question && question.url) {
            let msurl = question.url.substr(0, question.url.indexOf('.'));

            setMarkschemeURL(`/qb/${params.subject}/${msurl}_markscheme.html`);
            console.log("markscheme url:", `/qb/${params.subject}/${msurl}_markscheme.html`)
        }
    }, [question]);

    useEffect(() => {
        if (user.data) {
            // different than the ones in the Topic.js -- these ones are just arrays of the ID of the questions, not the questions themselves
            let paperOneQs = [];
            let paperTwoQs = [];
            let paperThreeQs = [];

            let current_subject_switchboard = subject_switchboard_mapping[params.subject];
            let current_subject_topic_number = subject_topic_number_mapping[params.subject];

            for (let item in current_subject) {
                for (let topic_dict in current_subject[item]['topics']) {
                    // .includes here is a stopgap
                    // because options topics for physics + biology have a (Core topics) and (Additional higher level topics) section
                    // these seperate sections are just represented as one general topic in switchboard
                    // so .includes allows us to just show all questions regardless of their (Core topics) or Additional higher level topics) string content
                    try {
                        if (current_subject[item]['topics'][topic_dict][current_subject_topic_number].includes(current_subject_switchboard[params.topic])) {
                            if (item.substr(item.indexOf(".") + 1, 1) == 1) {
                                paperOneQs.push(item);
                            }
                            else if (item.substr(item.indexOf(".") + 1, 1) == 2) {
                                paperTwoQs.push(item);
                            }
                            else if (item.substr(item.indexOf(".") + 1, 1) == 3) {
                                paperThreeQs.push(item);
                            }
                        }
                    }
                    catch (error) {
                        if (error instanceof TypeError) {
                            console.log("Question database entry missing topic data!", item, current_subject[item]['topics'][topic_dict])
                        } else {
                            throw error;  // re-throw the error unchanged
                        }

                    }
                }
            }
            setPaperOneQuestions(paperOneQs);
            setPaperTwoQuestions(paperTwoQs);
            setPaperThreeQuestions(paperThreeQs);
        }

    }, [params.subject, params.question, user.data]);

    useEffect(() => {
        if (paperThreeQuestions.length > 0) {
            setLastPaper(paperThreeQuestions);
            console.log("setLastPaper to paper 3")
        } else if (paperTwoQuestions.length > 0) {
            setLastPaper(paperTwoQuestions);
            console.log("setLastPaper to paper 2")
        } else {
            setLastPaper(paperOneQuestions);
            console.log("setLastPaper to paper 1")
        }
    }, [paperOneQuestions, paperTwoQuestions, paperThreeQuestions]);

    useEffect(() => {
        if (user.data) {
            if (user.data[subject_status_mapping[params.subject][0]] != undefined) {
                if (user.data[subject_status_mapping[params.subject][0]].includes(String(params.question))) {
                    setQuestionState('complete');
                } else if (user.data[subject_status_mapping[params.subject][1]].includes(String(params.question))) {
                    setQuestionState('revisit');
                } else {
                    setQuestionState('');
                }
            } else {
                console.log("writing new fields to database")
                let solved = subject_status_mapping[params.subject][0];
                let revisit = subject_status_mapping[params.subject][1];
                let payload = {};
                payload[solved] = [];
                payload[revisit] = [];
                database.collection("users").doc(currentUser.uid).set(payload, { merge: true })
                    .then(function () {
                        setQuestionState('');
                    })
                    .catch(function (error) {

                    });
            }
        }
    }, [user]);

    function handleSubmit(e) {
        let type = '';
        // type can be either 'complete' or 'revisit'
        e.preventDefault();

        type = e.target.id;

        database.collection('users').doc(currentUser.uid).get().then((doc) => {
            let data = doc.data();

            let solved_subject = data[subject_status_mapping[params.subject][0]];
            let revisit_subject = data[subject_status_mapping[params.subject][1]];

            let new_solved_subject = data[subject_status_mapping[params.subject][0]];
            let new_revisit_subject = data[subject_status_mapping[params.subject][1]];


            if (type == 'complete') {

                // delete from revisit section for clean up
                let index = revisit_subject.indexOf(params.question);
                if (index > -1) {
                    new_revisit_subject.splice(index, 1);
                }

                // add to new solved chemistry -- doing this just so database set command is only called once
                let indexComplete = solved_subject.indexOf(params.question);
                if (indexComplete > -1) {
                    new_solved_subject.splice(indexComplete, 1);
                }

                new_solved_subject.push(params.question);
                analytics.logEvent('question_completed', { reference_code: params.question, subject: params.subject });

            } else if (type == 'revisit') {

                // delete from revisit section for clean up
                let index = revisit_subject.indexOf(params.question);
                if (index > -1) {
                    new_revisit_subject.splice(index, 1);
                }

                // add to new solved chemistry -- doing this just so database set command is only called once
                let indexComplete = solved_subject.indexOf(params.question);
                if (indexComplete > -1) {
                    new_solved_subject.splice(indexComplete, 1);
                }

                new_revisit_subject.push(params.question);
                analytics.logEvent('question_revisit', { reference_code: params.question, subject: params.subject });
            } else if (type == 'unmark') {
                let index = revisit_subject.indexOf(params.question);
                if (index > -1) {
                    new_revisit_subject.splice(index, 1);
                    analytics.logEvent('question_unmark', { reference_code: params.question, subject: params.subject, previous_type: 'revisit' });
                }

                // add to new solved chemistry -- doing this just so database set command is only called once
                let indexComplete = solved_subject.indexOf(params.question);
                if (indexComplete > -1) {
                    new_solved_subject.splice(indexComplete, 1);
                    analytics.logEvent('question_unmark', { reference_code: params.question, subject: params.subject, previous_type: 'completed' });
                }

            }

            database.collection("users").doc(currentUser.uid).set({
                ...data,
                solved_subject: new_solved_subject,
                revisit_subject: new_revisit_subject,
            });

            if (type == "unmark") {
                console.log('unmarked');
            } else {
                if (!(lastPaper[lastPaper.indexOf(params.question) + 1])) {
                    console.log('no next question');
                } else {
                    nextQuestion();
                }
            }
        })
    }
    function nextQuestion() {
        setLoading(true);

        let current_subject_solved = subject_status_mapping[params.subject][0];

        let nextQuestion = '';

        if (paperOneQuestions.includes(params.question)) {
            for (let i = paperOneQuestions.indexOf(params.question); (nextQuestion === ''); i++) {
                if (paperOneQuestions[i + 1]) { // check if question exists
                    if (!user.data[current_subject_solved].includes(paperOneQuestions[i + 1])) { // check if question answered
                        nextQuestion = paperOneQuestions[i + 1];
                        break;
                    } else {
                        nextQuestion = '';
                    }
                } else {
                    // reached end of question paper 1 -- goto paper 2 -- not checking if its solved or not cuz
                    nextQuestion = paperTwoQuestions[0];
                    break;
                }
            }
        } else if (paperTwoQuestions.includes(params.question)) { // basically repeat of above except for paper 2s
            for (let i = paperTwoQuestions.indexOf(params.question); (nextQuestion === ''); i++) {
                if (paperTwoQuestions[i + 1]) {
                    if (!user.data[current_subject_solved].includes(paperTwoQuestions[i + 1])) {
                        nextQuestion = paperTwoQuestions[i + 1];
                        break;
                    } else {
                        nextQuestion = '';
                    }
                } else {
                    // reached end of question paper 2 -- goto paper 3 -- not checking if its solved or not cuz
                    nextQuestion = paperThreeQuestions[0];
                    break;
                }
            }
        } else if (paperThreeQuestions.includes(params.question)) {
            for (let i = paperThreeQuestions.indexOf(params.question); (nextQuestion === ''); i++) {
                if (paperThreeQuestions[i + 1]) {
                    if (!user.data[current_subject_solved].includes(paperThreeQuestions[i + 1])) {
                        nextQuestion = paperThreeQuestions[i + 1];
                        break;
                    } else {
                        nextQuestion = '';
                    }
                }
            }
            nextQuestion = paperThreeQuestions[paperThreeQuestions.indexOf(params.question) + 1];
        }

        history.push(`/${params.subject}/topic/${params.topic}/question/${nextQuestion}`);
        analytics.logEvent('question_skip', { reference_code: nextQuestion, subject: params.subject });
        setShowMarkscheme(false);
        setLoading(false);
    }



    function loadInjections() {
        let z, i, elmnt, file, xhttp;
        /* Loop through a collection of all HTML elements: */
        z = document.getElementsByTagName("*");
        for (i = 0; i < z.length; i++) {
            elmnt = z[i];
            /*search for elements with a certain atrribute:*/
            file = elmnt.getAttribute("inject");
            if (file) {
                /* Make an HTTP request using the attribute value as the file name: */
                xhttp = new XMLHttpRequest();
                xhttp.onreadystatechange = function () {
                    if (this.readyState == 4) {
                        if (this.status == 200) { elmnt.innerHTML = this.responseText; }
                        if (this.status == 404) { elmnt.innerHTML = "Page not found."; }
                        /* Remove the attribute, and call this function once more: */
                        elmnt.removeAttribute("inject");
                        if (window.MathJax != undefined) {
                            console.log("Typesetting!")
                            window.MathJax.typeset();
                        }
                        loadInjections();
                    }
                }
                xhttp.open("GET", file, true);
                xhttp.send();
                /* Exit the function: */
                return;
            }
        }


        // var finished_script = document.createElement("script");
        // finished_script.type = "text/javascript";
        // finished_script.innerHTML = "MathJax.Hub.Configured()"
        // document.getElementsByTagName("head")[0].appendChild(finished_script);
    }

    useEffect(() => {
        const trace = perf.trace("question_load_time");
        trace.start();
        loadInjections();
        console.log(markschemeURL)
        trace.stop();
        // console.log("last_paper array contents: ", lastPaper)
    });

    return (
        <div className='wrapper'>
            <Navigation />

            <div className={`question-page ${(questionState) ? (questionState) : ('')}`}>
                <div className='header'>
                    <div className='breadcrumb'>
                        <Link className='breadcrumb-link' to={`/${params.subject}`}>
                            <img src={left} />
                            <div className='breadcrumb-subject'>
                                {prettifySubject(params.subject)}
                            </div>
                        </Link>
                        /
                        <Link className='breadcrumb-link' to={`/${params.subject}/topic/${params.topic}`}>
                            <div className='breadcrumb-topic'>
                                {`${displayTopic(params.subject, params.topic)}`}
                            </div>
                        </Link>
                        /
                        <div className='breadcrumb-question'>
                            {`Question: ${params.question}`}
                            <div className='q-tag revisit'>
                                Revisit
                            </div>
                            <div className='q-tag complete'>
                                Completed
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`question-container`}>
                    <h3>Question</h3>
                    <div className={`question-out`}>
                        {/* <iframe id='question-frame' src={(question.data) ? (questionURL) : ''}></iframe> */}
                        <div inject={`${(question) ? (questionURL) : ''}`}></div>
                    </div>
                </div>

                <div className='markscheme-toggle' >
                    {(showMarkscheme) ? (
                        <div className='toggle' onClick={(e) => { (showMarkscheme) ? setShowMarkscheme(false) : setShowMarkscheme(true) }}>
                            <img src={eyeOff} /> Hide Markscheme
                        </div>
                    ) : (
                        <div className='toggle' onClick={(e) => { (showMarkscheme) ? setShowMarkscheme(false) : setShowMarkscheme(true) }}>
                            <img src={eye} /> Show Markscheme
                        </div>
                    )}
                </div>

                {(showMarkscheme) && (
                    <div className='markscheme-container'>
                        {/* <iframe id='markscheme-frame' src={(question.data) ? (`${markschemeURL}`) : ''}></iframe> */}

                        <div inject={`${(question) ? (`${markschemeURL}`) : ''}`}></div>
                    </div>
                )}

                <div className='action'>
                    {(questionState === 'complete') ? (
                        <button className='complete-button unmark' id="unmark" onClick={handleSubmit}>Unmark Complete</button>
                    ) : (
                        <button className='complete-button' id="complete" onClick={handleSubmit}>Mark Complete</button>
                    )}

                    {(questionState === 'revisit') ? (
                        <button className='revisit-button unmark' id="unmark" onClick={handleSubmit}>Unmark Revisit</button>
                    ) : (
                        <button className='revisit-button' id="revisit" onClick={handleSubmit}>Mark Revisit</button>
                    )}

                    {/* Gets rid of the next button if user has reached the final question of the topic (paper 2) */}
                    {(!(lastPaper[lastPaper.indexOf(params.question) + 1])) ? ('') : (
                        <button className={`skip-button disabled${loading}`} disabled={loading} onClick={nextQuestion}>Skip Question</button>
                    )}
                </div>


            </div>


        </div>
    )
}

export default Question;