const mathSyllabus = [
    {
        topic_no: 0,
        topic_name: 'Prior learning',
        sub_topics: [
            "Number",
            "Sets and numbers",
            "Algebra",
            "Trigonometry",
            "Geometry",
            "Coordinate geometry",
            "Statistics and Probability",
        ]
    },
    {
        topic_no: 1,
        topic_name: 'Algebra',
        sub_topics: [
            "1.1: Series, Sums, Sigma Notation",
            "1.2: Exponents, Logarithms, Laws, Base",
            "1.3: Binominal Theorem, Pascal's triangle",
        ]
    },
    {
        topic_no: 2,
        topic_name: 'Functions and equations',
        sub_topics: [
            "2.1",
            "2.2",
            "2.3",
            "2.4",
            "2.5",
            "2.6",
            "2.7",
            "2.8"
        ]
    },
    {
        topic_no: 3,
        topic_name: 'Circular functions and trigonometry',
        sub_topics: [
            "3.1",
            "3.2",
            "3.3",
            "3.4",
            "3.5",
            "3.6",
        ]
    },
    {
        topic_no: 4,
        topic_name: 'Vectors',
        sub_topics: [
            "4.1",
            "4.2",
            "4.3",
            "4.4",
        ]
    },
    {
        topic_no: 5,
        topic_name: 'Statistics & Probability',
        sub_topics: [
            "5.1",
            "5.2",
            "5.3",
            "5.4",
            "5.5",
            "5.6",
            "5.7",
            "5.8",
            "5.9",
        ]
    },
    {
        topic_no: 6,
        topic_name: 'Calculus',
        sub_topics: [
            "6.1",
            "6.2",
            "6.3",
            "6.4",
            "6.5",
            "6.6",
        ]
    }
];

export { mathSyllabus };