import React, { useState, useEffect } from 'react';
import { Router, Switch, Route, Link, NavLink, useHistory, useParams } from 'react-router-dom';

// currently commented out because with SL and HL having different exam requirements
// (as well as math questions being from a pre-2021 curriculum), these exam details are likely to be inaccurate
// import { exam_info_mapping } from './DisplayUtils';
import { subject_syllabus_mapping, subject_h1_mapping } from './DisplayUtils';

import Navigation from './Navigation';

const Subject = (props) => {

    const params = useParams();

    function displayTopics(type = 0) {
        let displayArr = [];
        let syllabus = subject_syllabus_mapping[params.subject][type];

        syllabus.forEach((item, index) => {
            let subtopics = [];

            item.sub_topics.forEach((subtopic) => {
                subtopics.push(
                    <div className='sub-tag' key={subtopic}>
                        {subtopic}
                    </div>
                )
            });

            displayArr.push(
                <Link to={`${params.subject}/topic/${item.topic_no}`} className='topic' key={item.topic_no}>
                    <div className='topic-no'>
                        {item.topic_no}
                    </div>
                    <div className='topic-name'>
                        {item.topic_name}
                    </div>
                    <div className='topic-subs'>
                        {/* {subtopics} */}
                    </div>
                </Link>
            );
        })

        return displayArr;
    }
    if (params.subject == 'math' || params.subject == 'further_math_hl' || params.subject == 'math_studies' || params.subject == 'environmental_systems_and_societies') {
        return (
            <div className='wrapper'>
                <Navigation />

                <div className='syllabus'>
                    <div className='header'>
                        <h1>{subject_h1_mapping[params.subject]}</h1>
                    </div>

                    {/* <div className='exam-info'>
                        <table>
                            <tbody>
                                <tr>
                                    <th>paper 1</th>
                                    <td>{exam_info_mapping[params.subject][0][0]}</td>
                                    <td>{exam_info_mapping[params.subject][0][1]}</td>
                                </tr>
                                <tr>
                                    <th>paper 2</th>
                                    <td>{exam_info_mapping[params.subject][1][0]}</td>
                                    <td>{exam_info_mapping[params.subject][1][1]}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div> */}
                    <h3>Core Topics</h3>
                    <div className='topic-container'>
                        {displayTopics()}
                    </div>
                </div>
            </div>
        );
    } else if (params.subject == 'math_hl') {
        return (
            <div className='wrapper'>
                <Navigation />

                <div className='syllabus'>
                    <div className='header'>
                        <h1>{subject_h1_mapping[params.subject]}</h1>
                    </div>

                    {/* <div className='exam-info'>
                        <table>
                            <tbody>
                                <tr>
                                    <th>paper 1</th>
                                    <td>{exam_info_mapping[params.subject][0][0]}</td>
                                    <td>{exam_info_mapping[params.subject][0][1]}</td>
                                </tr>
                                <tr>
                                    <th>paper 2</th>
                                    <td>{exam_info_mapping[params.subject][1][0]}</td>
                                    <td>{exam_info_mapping[params.subject][1][1]}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div> */}
                    <h3>Core Topics</h3>
                    <div className='topic-container'>
                        {displayTopics()}
                    </div>
                    <h3>Options Topics</h3>
                    <div className='topic-container options'>
                        {displayTopics(2)}
                    </div>
                </div>
            </div>
        );
    }
    else {
        return (
            <div className='wrapper'>
                <Navigation />

                <div className='syllabus'>
                    <div className='header'>
                        <h1>{subject_h1_mapping[params.subject]}</h1>
                    </div>

                    {/* <div className='exam-info'>
                    <table>
                        <tbody>
                            <tr>
                                <th>paper 1</th>
                                <td>{exam_info_mapping[params.subject][0][0]}</td>
                                <td>{exam_info_mapping[params.subject][0][1]}</td>
                            </tr>
                            <tr>
                                <th>paper 2</th>
                                <td>{exam_info_mapping[params.subject][1][0]}</td>
                                <td>{exam_info_mapping[params.subject][1][1]}</td>
                            </tr>
                        </tbody>
                    </table>
                </div> */}
                    <h3>Core Topics</h3>
                    <div className='topic-container'>
                        {displayTopics()}
                    </div>
                    <h3>Additional Higher Level Topics</h3>
                    <div className='topic-container AHL'>
                        {displayTopics(1)}
                    </div>
                    <h3>Options Topics</h3>
                    <div className='topic-container options'>
                        {displayTopics(2)}
                    </div>
                </div>
            </div>
        );
    }


}

export default Subject;