import { physicsSyllabus, physicsAHLSyllabus, physicsOptionsSyllabus } from './syllabi/physics_syllabus';
import { mathSyllabus } from './syllabi/math_syllabus';
import { chemistrySyllabus, chemistryAHLSyllabus, chemistryOptionsSyllabus } from './syllabi/chemistry_syllabus';
import { biologySyllabus, biologyAHLSyllabus, biologyOptionsSyllabus } from './syllabi/biology_syllabus';
import { math_HLOptionsSyllabus, math_HLSyllabus } from './syllabi/math_hl_syllabus';
import { further_math_HLSyllabus } from './syllabi/further_math_hl_syllabus';
import { math_studiesSyllabus } from './syllabi/math_studies_syllabus';
import { geographyAHLSyllabus, geographyOptionsSyllabus, geographySyllabus } from './syllabi/geography_syllabus';
import { environmental_systems_and_societiesSyllabus } from './syllabi/environmental_systems_and_societies_syllabus';

import { mathTopicSwitchboard, chemistryTopicSwitchboard, physicsTopicSwitchboard, biologyTopicSwitchBoard, math_HLTopicSwitchboard, further_math_HLTopicSwitchboard, math_studiesTopicSwitchboard, geographyTopicSwitchBoard, environmental_systems_and_societiesTopicSwitchboard } from './syllabi/Switchboards';

function prettifySubject(subject) {
    switch (subject) {
        case 'math':
            return 'Math SL';
        case 'chemistry':
            return 'Chemistry';
        case 'physics':
            return 'Physics';
        case 'biology':
            return 'Biology';
        case 'math_hl':
            return 'Math HL';
        case 'further_math_hl':
            return 'Further Math HL';
        case 'math_studies':
            return 'Math Studies';
        case 'geography':
            return 'Geography';
        case 'environmental_systems_and_societies':
            return 'Environmental Systems'
        default:
            return subject;
    }
}

const subject_import_mapping = {
    'math': '40-dp-mathematics-sl',
    'chemistry': '45-dp-chemistry',
    'physics': '46-dp-physics',
    'biology': '43-dp-biology',
    'math_hl': '7-dp-mathematics-hl',
    'further_math_hl': '42-dp-further-mathematics-hl',
    'math_studies': '41-dp-mathematical-studies',
    'geography': '1-dp-geography',
    'environmental_systems_and_societies': '49-dp-environmental-systems-and-societies',
};

const subject_syllabus_mapping = {
    'math': [mathSyllabus],
    'chemistry': [chemistrySyllabus, chemistryAHLSyllabus, chemistryOptionsSyllabus],
    'physics': [physicsSyllabus, physicsAHLSyllabus, physicsOptionsSyllabus],
    'biology': [biologySyllabus, biologyAHLSyllabus, biologyOptionsSyllabus],
    'math_hl': [math_HLSyllabus, null, math_HLOptionsSyllabus],
    'further_math_hl': [further_math_HLSyllabus],
    'math_studies': [math_studiesSyllabus],
    'geography': [geographySyllabus, geographyAHLSyllabus, geographyOptionsSyllabus],
    'environmental_systems_and_societies': [environmental_systems_and_societiesSyllabus],
};

const subject_h1_mapping = {
    'math': "Mathematics SL",
    'chemistry': "Chemistry",
    'physics': "Physics",
    'biology': "Biology",
    'math_hl': "Mathematics HL",
    'further_math_hl': "Further Mathematics HL",
    'math_studies': "Mathematical Studies",
    'geography': "Geography",
    'environmental_systems_and_societies': 'Environmental Systems and Societies',
}

const subject_switchboard_mapping = {
    'math': mathTopicSwitchboard,
    'chemistry': chemistryTopicSwitchboard,
    'physics': physicsTopicSwitchboard,
    'biology': biologyTopicSwitchBoard,
    'math_hl': math_HLTopicSwitchboard,
    'further_math_hl': further_math_HLTopicSwitchboard,
    'math_studies': math_studiesTopicSwitchboard,
    'geography': geographyTopicSwitchBoard,
    'environmental_systems_and_societies': environmental_systems_and_societiesTopicSwitchboard,
}

const subject_topic_number_mapping = {
    'math': 'topic_1',
    'chemistry': 'topic_2',
    'physics': 'topic_2',
    'biology': 'topic_2',
    'math_hl': 'topic_1',
    'further_math_hl': 'topic_1',
    'math_studies': 'topic_1',
    'geography': 'topic_2',
    'environmental_systems_and_societies': 'topic_1',
}

const subject_status_mapping = {
    'math': ['solved_math', 'revisit_math'],
    'chemistry': ['solved_chemistry', 'revisit_chemistry'],
    'physics': ['solved_physics', 'revisit_physics'],
    'biology': ['solved_biology', 'revisit_biology'],
    'math_hl': ['solved_math_hl', 'revisit_math_hl'],
    'further_math_hl': ['solved_further_math_hl', 'revisit_further_math_hl'],
    'math_studies': ['solved_math_studies', 'revisit_math_studies'],
    'geography': ['solved_geography', 'revisit_geography'],
    'environmental_systems_and_societies': ['solved_environmental_systems_and_societies', 'revisit_environmental_systems_and_societies'],
};

const exam_info_mapping = {
    'math': [
        ["no calculator", "1:30 hour"],
        ["yes calculator", "1:30 hour"]
    ],
    'chemistry': [
        ["multiple choice", "0:45 hour"],
        ["structured questions", "1:15 hour"]
    ],
    'physics': [
        ["multiple choice", "1:00 hour"],
        ["structured questions", "2:15 hour"]
    ],
    'biology': [
        ["multiple choice", "1:00 hour"],
        ["structured questions", "2:15 hour"]
    ]
}

function displayTopic(subject, paramsTopic) {
    let syllabus_type_mapping = {
        0: "Topic",
        1: "AHL Topic",
        2: "Option"
    }
    for (let syllabus in subject_syllabus_mapping[subject]) {
        for (let topic in subject_syllabus_mapping[subject][syllabus]) {
            if (subject_syllabus_mapping[subject][syllabus][topic].topic_no == paramsTopic) {
                if (syllabus == 0) {
                    return ("Topic " + paramsTopic + ": " + subject_syllabus_mapping[subject][syllabus][topic].topic_name);
                }
                if (syllabus == 1) {
                    return ("Topic " + paramsTopic + ": " + subject_syllabus_mapping[subject][syllabus][topic].topic_name + " (AHL)");
                }
                if (syllabus == 2) {
                    return ("Option " + paramsTopic + ": " + subject_syllabus_mapping[subject][syllabus][topic].topic_name);
                }
            }
        }
    }
}

export { displayTopic, prettifySubject, subject_syllabus_mapping, exam_info_mapping, subject_h1_mapping, subject_switchboard_mapping, subject_topic_number_mapping, subject_status_mapping, subject_import_mapping };