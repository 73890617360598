const geographySyllabus = [
    {
        topic_no: 1,
        topic_name: 'Populations in transition',
        sub_topics: [
            "Population change",
            "Responses to high and low fertility",
            "Movement responses—migration",
            "Gender and change",
        ]
    },
    {
        topic_no: 2,
        topic_name: 'Disparities in wealth and development',
        sub_topics: [
            "Measurements of regional and global disparities",
            "Origin of disparities",
            "Disparities and change",
            "Reducing disparities",
        ]
    },
    {
        topic_no: 3,
        topic_name: 'Patterns in environmental quality and sustainability',
        sub_topics: [
            "Atmosphere and change",
            "Soil and change",
            "Water and change",
            "Biodiversity and change",
            "Sustainability and the environment",
        ]
    },
    {
        topic_no: 4,
        topic_name: 'Patterns in resource consumption',
        sub_topics: [
            "Patterns of resource consumption",
            "Changing patterns of energy consumption",
            "Conservation strategies",
        ]
    },
]

const geographyAHLSyllabus = [
    {
        topic_no: 5,
        topic_name: 'Measuring global interactions',
        sub_topics: [
            "Global participation",
            "Global core and periphery",
        ]
    },
    {
        topic_no: 6,
        topic_name: 'Changing space—the shrinking world',
        sub_topics: [
            "Time–space convergence and the reduction in the friction of distance",
            "Extension and density of networks",
        ]
    },
    {
        topic_no: 7,
        topic_name: 'Economic interactions and flows',
        sub_topics: [
            "Financial flows",
            "Labour flows",
            "Information flows",
        ]
    },
    {
        topic_no: 8,
        topic_name: 'Environmental change',
        sub_topics: [
            "Degradation through raw material production",
            "The effects of transnational manufacturing and services",
            "Transboundary pollution",
            "Homogenization of landscapes",
        ]
    },
    {
        topic_no: 9,
        topic_name: 'Sociocultural exchanges',
        sub_topics: [
            "Cultural diffusion: the process",
            "Consumerism and culture",
            "Sociocultural integration",
        ]
    },
    {
        topic_no: 10,
        topic_name: 'Political outcomes',
        sub_topics: [
            "Loss of sovereignty",
            "Responses",
        ]
    },
    {
        topic_no: 11,
        topic_name: 'Global interactions at the local level',
        sub_topics: [
            "Defining glocalization",
            "Adoption of globalization",
            "Local responses to globalization",
            "Alternatives",
        ]
    },
]

const geographyOptionsSyllabus = [
    {
        topic_no: 'A',
        topic_name: 'Freshwater—issues and conflicts',
        sub_topics: [
            "The water system",
            "Drainage basins and flooding",
            "Management issues and strategies",
            "Competing demands for water",
        ]
    },
    {
        topic_no: 'B',
        topic_name: 'Oceans and their coastal margins',
        sub_topics: [
            "Introduction to oceans",
            "Oceans and climate",
            "The value of oceans",
            "Geopolitics of oceans",
            "Coastal margins",
            "Coral reefs and mangroves",
        ]
    },
    {
        topic_no: 'C',
        topic_name: 'Extreme environments',
        sub_topics: [
            "Challenging environments",
            "The physical characteristics of extreme environments",
            "Opportunities and challenges for management",
            "Sustainability",
        ]
    },
    {
        topic_no: 'D',
        topic_name: 'Hazards and disasters—risk assessment and response',
        sub_topics: [
            "Characteristics of hazards",
            "Vulnerability",
            "Risk and risk assessment",
            "Disasters",
            "Adjustments and responses to hazards and disasters",
        ]
    },
    {
        topic_no: 'E',
        topic_name: 'Leisure, sport and tourism',
        sub_topics: [
            "Leisure",
            "Leisure at the international scale: tourism",
            "Leisure at the international scale: sport",
            "Leisure at the national/regional scale: tourism",
            "Leisure at the national/regional scale: sport",
            "Leisure at the local scale: tourism",
            "Leisure at the local scale: sport and recreation",
            "Sustainable tourism",
        ]
    },
    {
        topic_no: 'F',
        topic_name: 'The geography of food and health',
        sub_topics: [
            "Health",
            "Food",
            "Disease",
        ]
    },
    {
        topic_no: 'G',
        topic_name: 'Urban environments',
        sub_topics: [
            "Urban populations",
            "Urban land use",
            "Urban stress",
            "The sustainable city",
        ]
    },
];

export { geographySyllabus, geographyAHLSyllabus, geographyOptionsSyllabus };