const further_math_HLSyllabus = [
    {
        topic_no: 1,
        topic_name: 'Linear Algebra',
        sub_topics: [
            "1.1",
            "1.2",
            "1.3",
            "1.4",
            "1.5",
            "1.6",
            "1.7",
            "1.8",
            "1.9",
        ]
    },
    {
        topic_no: 2,
        topic_name: 'Geometry',
        sub_topics: [
            "2.1",
            "2.2",
            "2.3",
            "2.4",
            "2.5",
            "2.6",
            "2.7",
            "2.8",
        ]
    },
    {
        topic_no: 3,
        topic_name: 'Statistics and probability',
        sub_topics: [
            "3.1",
            "3.2",
            "3.3",
            "3.4",
            "3.5",
            "3.6",
            "3.7",
        ]
    },
    {
        topic_no: 4,
        topic_name: 'Sets, relations and groups',
        sub_topics: [
            "4.1",
            "4.2",
            "4.3",
            "4.4",
            "4.5",
            "4.6",
            "4.7",
            "4.8",
            "4.9",
            "4.10",
            "4.11",
            "4.12",
        ]
    },
    {
        topic_no: 5,
        topic_name: 'Calculus',
        sub_topics: [
            "5.1",
            "5.2",
            "5.3",
            "5.4",
            "5.5",
            "5.6",
            "5.7",
        ]
    },
    {
        topic_no: 6,
        topic_name: 'Discrete mathematics',
        sub_topics: [
            "6.1",
            "6.2",
            "6.3",
            "6.4",
            "6.5",
            "6.6",
            "6.7",
            "6.8",
            "6.9",
            "6.10",
            "6.11",
        ]
    }
];

export { further_math_HLSyllabus };