import React, { useState, useEffect } from 'react';
import { Router, Switch, Route, Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import useWindowSize from './windowSizeHook'

import ChevronLeft from './assets/chevron-left.svg';

const Navigation = (props) => {

    const [error, setError] = useState('');

    const history = useHistory();
    const size = useWindowSize();
    const location = useLocation();

    const getSliderState = () => {
        let state = sessionStorage.getItem('slider');

        if (!state) {
            return null;
        } else {
            return state;
        }
    }

    const setSliderState = (data) => {
        sessionStorage.setItem('slider', data);
    }

    const [sliderOpen, setSliderOpen] = useState(getSliderState() ? getSliderState() : 'true');

    useEffect(() => {
        let r = document.querySelector(':root');

        if (sliderOpen === 'true') {
            // state when slider is open

            r.style.setProperty('--side-nav-width', '250px');
            setSliderState('true');

        } else {
            // state when slider is not open

            r.style.setProperty('--side-nav-width', '0px');
            setSliderState('false');

        }

    }, [sliderOpen]);

    // close sidebar if window width < 900
    useEffect(() => {
        if (location.pathname == "/") {
            setSliderOpen(true);
            let r = document.querySelector(':root');
            r.style.setProperty('--side-nav-width', '250px');
            setSliderState('true');
        }
        else if (size['width'] < 1000 && location.pathname != "/") {
            setSliderOpen(false);
        }
    }, [size['width'], location]);

    return (
        <>
            <div className={`side-nav slider${sliderOpen}`}>
                <div className='headmast'>
                    <NavLink exact={true} to='/' activeClassName='active' className='header-link'>
                        <h2>pestle</h2>
                    </NavLink>
                </div>
                <div className='subjects'>
                    <NavLink to='/math' className='subject-link'>
                        math sl
                </NavLink>
                    <NavLink to='/math_hl' className='subject-link'>
                        math hl
                </NavLink>
                    <NavLink to='/math_studies' className='subject-link'>
                        math studies
                </NavLink>
                    <NavLink to='/further_math_hl' className='subject-link'>
                        further math hl
                </NavLink>
                    <NavLink to='/chemistry' className='subject-link'>
                        chemistry
                </NavLink>
                    <NavLink to='/physics' className='subject-link'>
                        physics
                </NavLink>
                    <NavLink to='/biology' className='subject-link'>
                        biology
                </NavLink>
                    <NavLink to='/geography' className='subject-link'>
                        geography
                </NavLink>
                    <NavLink to='/environmental_systems_and_societies' className='subject-link'>
                        environmental systems
                </NavLink>
                    {/* <NavLink to='/economics' className='subject-link disabled'>
                    economics hl
                </NavLink>
                <NavLink to='/english' className='subject-link disabled'>
                    english a hl
                </NavLink>
                <NavLink to='/history' className='subject-link disabled'>
                    history hl
                </NavLink> */}
                </div>
                {/* <div style={{ flexGrow: 1 }}>

                </div> */}
                <div className='actions'>
                    <div className='hide-nav' onClick={e => setSliderOpen('false')}>
                        hide side
                </div>
                    {(error) && (
                        <div className='logout-error'>
                            {error}
                        </div>
                    )}
                </div>
            </div>
            <div className={`open-nav opened${sliderOpen}`} onClick={e => setSliderOpen((sliderOpen === 'true') ? 'false' : 'true')}>
                <img src={ChevronLeft} />
            </div>
        </>
    );
}

export default Navigation;