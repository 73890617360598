const environmental_systems_and_societiesSyllabus = [
    {
        topic_no: 1,
        topic_name: 'Foundations of environmental systems and societies',
        sub_topics: [
            "1.1 Environmental value systems",
            "1.2 Systems and models",
            "1.3 Energy and equilibria",
            "1.4 Sustainability",
            "1.5 Humans and pollution",
        ]
    },
    {
        topic_no: 2,
        topic_name: 'Ecosystems and ecology',
        sub_topics: [
            "2.1 Species and populations",
            "2.2 Communities and ecosystems",
            "2.3 Flows of energy and matter",
            "2.4 Biomes, zonation and succession",
            "2.5 Investigating ecosystems",
        ]
    },
    {
        topic_no: 3,
        topic_name: 'Biodiversity and conservation',
        sub_topics: [
            "3.1 An introduction to biodiversity",
            "3.2 Origins of biodiversity",
            "3.3 Threats to biodiversity",
            "3.4 Conservation of biodiversity",
            "Sustainability and the environment",
        ]
    },
    {
        topic_no: 4,
        topic_name: 'Water and aquatic food production systems and societies',
        sub_topics: [
            "4.1 Introduction to water systems",
            "4.2 Access to fresh water",
            "4.3 Aquatic food production systems",
            "4.4 Water pollution",
        ]
    },
    {
        topic_no: 5,
        topic_name: 'Soil systems and terrestrial food production systems and societies',
        sub_topics: [
            "5.1 Introduction to soil systems",
            "5.2 Terrestrial food production systems and food choices",
            "5.3 Soil degradation and conservation",
        ]
    },
    {
        topic_no: 6,
        topic_name: 'Atmospheric systems and societies',
        sub_topics: [
            "6.1 Introduction to the atmosphere",
            "6.2 Stratospheric ozone",
            "6.3 Photochemical smog",
            "6.4 Acid deposition",
        ]
    },
    {
        topic_no: 7,
        topic_name: 'Climate change and energy production',
        sub_topics: [
            "7.1 Energy choices and security",
            "7.2 Climate change—causes and impacts",
            "7.3 Climate change—mitigation and adaptation",
        ]
    },
    {
        topic_no: 8,
        topic_name: 'Human systems and resource use',
        sub_topics: [
            "8.1 Human population dynamics",
            "8.2 Resource use in society",
            "8.3 Solid domestic waste",
            "8.4 Human population carrying capacity",
        ]
    },
]

export { environmental_systems_and_societiesSyllabus };