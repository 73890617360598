import './App.css';
import { BrowserRouter as Router, Switch, Route, Link, Redirect, useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';

import { AuthProvider, useAuth } from './contexts/AuthContext';
import { analytics, remoteConfig } from './firebase';

import Main from './Main';
import Landing from './Landing';

import Topic from './Topic';
import Question from './Question';
import Subject from './Subject';

function AuthRoute({ component: Component, ...rest }) {
  const { currentUser, logout } = useAuth();
  async function handleLogout() {
    try {
      await logout();
    } catch {
      console.log("Failed to log out");
    }
  }
  remoteConfig.fetchAndActivate()
    .then(() => {
      if (remoteConfig.getValue("closed_exam_season")._value == "true") {
        handleLogout();
      }
    })
    .catch((err) => {
      console.log(err);
    });

  return (
    <Route
      {...rest}
      render={(props) =>
        currentUser ? (
          <Component {...props} />
        ) : (
          <Redirect to='/login' />
        )
      }
    />
  );
}

function App() {
  const history = useHistory();

  useEffect(() => {
    trackPageView(); // To track the first pageview upon load
    history.listen(trackPageView); // To track the subsequent pageviews
  }, [history]);

  function trackPageView() {
    analytics.logEvent('page_view', { page_path: window.location.pathname });
  }

  return (
    <AuthProvider>
      <Switch>
        <AuthRoute exact path='/' component={Main} />
        <Route exact path='/login' component={Landing} />

        <AuthRoute exact path='/:subject/' component={Subject} />
        <AuthRoute exact path='/:subject/topic/:topic' component={Topic} />
        <AuthRoute exact path='/:subject/topic/:topic/question/:question' component={Question} />
      </Switch>
    </AuthProvider>
  );
}

export default App;
