const chemistrySyllabus = [
    {
        topic_no: 1,
        topic_name: 'Stoichiometric relationships',
        sub_topics: [
            "Introduction to the particulate nature of matter and chemical change",
            "The mole concept",
            "Reacting masses and volumes",
        ]
    },
    {
        topic_no: 2,
        topic_name: 'Atomic structure',
        sub_topics: [
            "The nuclear atom",
            "Electron Configuration",
        ]
    },
    {
        topic_no: 3,
        topic_name: 'Periodicity',
        sub_topics: [
            "Periodic table",
            "Periodic trends",
        ]
    },
    {
        topic_no: 4,
        topic_name: 'Chemical bonding and structure',
        sub_topics: [
            "Ionic bonding and structure",
            "Covalent bonding",
            "Covalent structures",
            "Intermolecular forces",
            "Metallic bonding",
        ]
    },
    {
        topic_no: 5,
        topic_name: 'Energetics/Thermochemistry',
        sub_topics: [
            "Measuring energy changes",
            "Hess's Law",
            "Bond enthalpies",
        ]
    },
    {
        topic_no: 6,
        topic_name: 'Chemical kinetics',
        sub_topics: [
            "Collision theory and rates of reaction",
        ]
    },
    {
        topic_no: 7,
        topic_name: 'Equilibrium',
        sub_topics: [
            "Equilibrium",
        ]
    },
    {
        topic_no: 8,
        topic_name: 'Acids and bases',
        sub_topics: [
            "Theories of acids and bases",
            "Properties of acids and bases",
            "The pH scale",
            "Strong and weak acids and bases",
            "Acid deposition",
        ]
    },
    {
        topic_no: 9,
        topic_name: 'Redox processes',
        sub_topics: [
            "Oxidation and reduction",
            "Electrochemical cells",
        ]
    },
    {
        topic_no: 10,
        topic_name: 'Organic chemistry',
        sub_topics: [
            "Fundamentals of organic chemistry",
            "Functional group chemistry"
        ]
    },
    {
        topic_no: 11,
        topic_name: 'Measurement and data processing',
        sub_topics: [
            "Uncertainties and errors in measurement and results",
            "Graphical techniques",
            "Spectroscopic identification of organic compounds"
        ]
    }
];
const chemistryAHLSyllabus = [
    {
        topic_no: 12,
        topic_name: 'Atomic Structure',
        sub_topics: [
            "Electrons in atoms",
            "The mole concept",
            "Reacting masses and volumes",
        ]
    },
    {
        topic_no: 13,
        topic_name: 'The periodic table—the transition metals',
        sub_topics: [
            "First-row d-block elements",
            "Coloured complexes",
        ]
    },
    {
        topic_no: 14,
        topic_name: 'Chemical bonding and structure',
        sub_topics: [
            "Covalent bonding and electron domain and molecular geometries",
            "Hybridization",
        ]
    },
    {
        topic_no: 15,
        topic_name: 'Energetics/Thermochemistry',
        sub_topics: [
            "Energy cycles",
            "Entropy and spotaneity",
        ]
    },
    {
        topic_no: 16,
        topic_name: 'Chemical kinetics',
        sub_topics: [
            "Rate expression and reaction mechanism",
            "Activation energy",
        ]
    },
    {
        topic_no: 17,
        topic_name: 'Equilibrium',
        sub_topics: [
            "The equilibrium law",
        ]
    },
    {
        topic_no: 18,
        topic_name: 'Acids and bases',
        sub_topics: [
            "Lewis acids and bases",
            "Calculations involving acids and bases",
            "pH curves",
        ]
    },
    {
        topic_no: 19,
        topic_name: 'Redox processes',
        sub_topics: [
            "Electrochemical cells",
        ]
    },
    {
        topic_no: 20,
        topic_name: 'Organic chemistry',
        sub_topics: [
            "Types of organic reactions",
            "Synthetic routes",
            "Stereoisomerism",
        ]
    },
    {
        topic_no: 21,
        topic_name: 'Measurement and data processing',
        sub_topics: [
            "Spectroscopic identification of organic compounds"
        ]
    }
];
const chemistryOptionsSyllabus = [
    {
        topic_no: 'A',
        topic_name: 'Materials',
        sub_topics: [
            "Materials science introduction",
            "Metals and inductively coupled plasma (ICP) spectroscopy",
            "Catalysts",
            "Liquid crystals",
            "Polymers",
            "Nanotechnology",
            "Environmental impact—plastics",
            "Superconducting metals and X-ray crystallography (HL only)",
            "Condensation polymers (HL only)",
            "Environmental impact—heavy metals (HL only)",
        ]
    },
    {
        topic_no: 'B',
        topic_name: 'Biochemistry',
        sub_topics: [
            "Introduction to biochemistry",
            "Proteins and enzymes",
            "Lipids",
            "Carbohydrates",
            "Vitamins",
            "Biochemistry and the environment",
            "Proteins and enzymes (HL only)",
            "Nucleic acids (HL only)",
            "Biological pigments (HL only)",
            "Stereochemistry in biomolecules (HL only)",
        ]
    },
    {
        topic_no: 'C',
        topic_name: 'Energy',
        sub_topics: [
            "Energy sources",
            "Fossil fuels",
            "Nuclear fusion and fission",
            "Solar energy",
            "Environmental impact—global warming",
            "Electrochemistry, rechargeable batteries and fuel cells (HL only)",
            "Nuclear fusion and nuclear fission (HL only)",
            "Photovoltaic and dye-sensitized solar cells (HL only)",
        ]
    },
    {
        topic_no: 'D',
        topic_name: 'Medicinal chemistry',
        sub_topics: [
            "Pharmaceutical products and drug action",
            "Aspirin and penicillin",
            "Opiates",
            "pH regulation of the stomach",
            "Anti-viral medications",
            "Environmental impact of some medications",
            "Taxol—a chiral auxiliary case study (HL only)",
            "Nuclear medicine (HL only)",
            "Drug detection and analysis (HL only)",
        ]
    },
];


export { chemistrySyllabus, chemistryAHLSyllabus, chemistryOptionsSyllabus };