import React, { useState, useEffect } from 'react';
import { Router, Switch, Route, Link, useHistory } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import { database } from './firebase';

import Navigation from './Navigation';

const Main = (props) => {
    
    const { currentUser, logout } = useAuth();
    const history = useHistory();

    async function handleLogout() {
        try {
            await logout();
            history.push('/')
        } catch {
            console.log("Log-out error");
        }
    }

    return (
        <div className='wrapper'>
            <Navigation />
            <div className='main-message'>
                <div className='embroidery'>
                    pestle.
                </div>
                <Link to='/' className='logout' onClick={handleLogout}>
                    log out
                </Link>
            </div>
        </div>
    );
}

export default Main;