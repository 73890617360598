const math_HLSyllabus = [
    {
        topic_no: 1,
        topic_name: 'Algebra',
        sub_topics: [
            "1.1",
            "1.2",
            "1.3",
            "1.4",
            "1.5",
            "1.6",
            "1.7",
            "1.8",
            "1.9",
        ]
    },
    {
        topic_no: 2,
        topic_name: 'Functions and equations',
        sub_topics: [
            "2.1",
            "2.2",
            "2.3",
            "2.4",
            "2.5",
            "2.6",
            "2.7",
        ]
    },
    {
        topic_no: 3,
        topic_name: 'Circular functions and trigonometry',
        sub_topics: [
            "3.1",
            "3.2",
            "3.3",
            "3.4",
            "3.5",
            "3.6",
            "3.7",
        ]
    },
    {
        topic_no: 4,
        topic_name: 'Vectors',
        sub_topics: [
            "4.1",
            "4.2",
            "4.3",
            "4.4",
            "4.5",
            "4.6",
            "4.7",
        ]
    },
    {
        topic_no: 5,
        topic_name: 'Statistics & Probability',
        sub_topics: [
            "5.1",
            "5.2",
            "5.3",
            "5.4",
            "5.5",
            "5.6",
            "5.7",
        ]
    },
    {
        topic_no: 6,
        topic_name: 'Calculus',
        sub_topics: [
            "6.1",
            "6.2",
            "6.3",
            "6.4",
            "6.5",
            "6.6",
            "6.7",
        ]
    }
];

const math_HLOptionsSyllabus = [
    {
        topic_no: 7,
        topic_name: 'Statistics & Probability',
        sub_topics: [
            "7.1",
            "7.2",
            "7.3",
            "7.4",
            "7.5",
            "7.6",
            "7.7",
        ]
    },
    {
        topic_no: 8,
        topic_name: 'Sets, relations, and groups',
        sub_topics: [
            "8.1",
            "8.2",
            "8.3",
            "8.4",
            "8.5",
            "8.6",
            "8.7",
            "8.8",
            "8.9",
            "8.10",
            "8.11",
            "8.12",
        ]
    },
    {
        topic_no: 9,
        topic_name: 'Calculus',
        sub_topics: [
            "9.1",
            "9.2",
            "9.3",
            "9.4",
            "9.5",
            "9.6",
            "9.7",
        ]
    },
    {
        topic_no: 10,
        topic_name: 'Discrete mathematics',
        sub_topics: [
            "10.1",
            "10.2",
            "10.3",
            "10.4",
            "10.5",
            "10.6",
            "10.7",
            "10.8",
            "10.9",
            "10.10",
            "10.11",
        ]
    },

];

export { math_HLSyllabus, math_HLOptionsSyllabus };